import React, {useEffect} from 'react';
import {NextPage} from 'next';

import Blank from '@layouts/Blank';
import PageWithLayoutType from 'types/pageWithLayout';

// import { useRouter } from 'next/router';

const Error404: NextPage = () => {
  // const router = useRouter();
  useEffect(() => {
    // router.push('/');
  }, []);

  return <div>페이지를 찾을수 없습니다.</div>;
};

(Error404 as PageWithLayoutType).layout = Blank;

export default Error404;
